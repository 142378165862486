<template>
  <USlideover v-bind="$attrs" :ui="ui" class="dark">
    <slot />
  </USlideover>
</template>

<script setup lang="ts">
const ui = {
  background: 'dark:bg-gray-950',
  base: 'pt-4 pb-10 px-6 dark:text-white overflow-y-auto overflow-x-hidden',
  width: 'w-full max-w-sm',
  overlay: {
    background: 'dark:bg-gray-950/50'
  }
}
</script>
